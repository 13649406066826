<template>
	<div>
		<a-modal v-model:visible="visible" width="800px" @cancel="close">
			<template #title>
				<modalHeader title="报废申请"></modalHeader>
			</template>
			<template #footer>
				<a-button @click="close">取消</a-button>
				<a-button @click="onSubmit" type="primary">提交申请</a-button>
			</template>
			
			<a-form ref="formRef" :model="formModal" :label-col="{ span: 6 }">
				<a-row>
					<a-col :span="13">
						<a-form-item label="报废资产">
							{{showFormModal.name}}
							<!-- <assetsSelect v-model:value="assetsIds" @change="onAssetsChange" placeholder="请选择报废资产">
								<template #value>
									{{ showFormModal.name }}
								</template>
							</assetsSelect> -->
							<!-- <a-input v-model:value="formModal.nameOrCode" @change="onAssetDetail" placeholder="请输入报废资产编号或资产名称"></a-input> -->
						</a-form-item>
						
						<a-form-item name="voucherNo" label="会计凭证号" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-input v-model:value="formModal.voucherNo" placeholder="请输入会计凭证号"></a-input>
							<!-- {{formModal.voucherNo}} -->
						</a-form-item>
						
						<a-form-item name="cause" label="报废原因" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-select v-model:value="formModal.cause"  allow-clear>
								<a-select-option :value="1">盘亏</a-select-option>
								<a-select-option :value="2">损坏</a-select-option>
								<a-select-option :value="3">超过年限</a-select-option>
							</a-select>
							
						</a-form-item>
						<a-form-item label="补充说明">
							<a-input v-model:value="formModal.explain" placeholder="请输入补充说明"></a-input>
							<!-- {{formModal.explain}} -->
						</a-form-item>
						
						<a-form-item name="fileList" label="申请资料" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<!-- <a :href="formModal.fileList"></a> -->
							<filesUpload v-model:value="formModal.fileList"></filesUpload>
							<p>注：建议上传pdf格式的文件，大小不超过100M</p>
						</a-form-item>
					</a-col>
					<a-col :span="11">
						<div class="ui-box">
							<a-form-item label="资产编号" class="ui-result__item" :label-col="{ span: 9 }">
								{{showFormModal.code}}
							</a-form-item>
							<a-form-item label="资产分类名称" class="ui-result__item" :label-col="{ span: 9 }">
								{{showFormModal.classifyName}}
							</a-form-item>
							<a-form-item label="资产名称" class="ui-result__item" :label-col="{ span: 9 }">
								{{showFormModal.name}}
							</a-form-item>
							<a-form-item label="取得方式" class="ui-result__item" :label-col="{ span: 9 }">
								<!-- {{showFormModal.gainType}} -->
								<div v-if="showFormModal.gainType === 1">新购</div>
								<div v-if="showFormModal.gainType === 2">调拨</div>
								<div v-if="showFormModal.gainType === 3">接受捐赠</div>
								<div v-if="showFormModal.gainType === 4">自建</div>
								<div v-if="showFormModal.gainType === 5">置换</div>
								<div v-if="showFormModal.gainType === 6">盘盈</div>
								<div v-if="showFormModal.gainType === 7">自行研制</div>
								<div v-if="showFormModal.gainType === 8">其他</div>
							</a-form-item>
							<a-form-item label="取得日期" class="ui-result__item" :label-col="{ span: 9 }">
								{{transDateTime(showFormModal.gainTime)}}
							</a-form-item>
							<a-form-item label="设备用途" class="ui-result__item" :label-col="{ span: 9 }">
								<!-- {{showFormModal.useType}} -->
								<div v-if="showFormModal.useType === 1">办公</div>
								<div v-if="showFormModal.useType === 2">教学</div>
								<div v-if="showFormModal.useType === 3">培训</div>
								<div v-if="showFormModal.useType === 4">执法</div>
								<div v-if="showFormModal.useType === 5">窗口</div>
								<div v-if="showFormModal.useType === 6">专用</div>
								<div v-if="showFormModal.useType === 7">其他</div>
								<div v-if="showFormModal.useType === 8">医疗</div>
								<div v-if="showFormModal.useType === 9">科研</div>
							</a-form-item>
							<a-form-item label="规格型号" class="ui-result__item" :label-col="{ span: 9 }">
								{{showFormModal.model}}
							</a-form-item>
							<a-form-item label="价值(元)" class="ui-result__item" :label-col="{ span: 9 }">
								{{showFormModal.amount}}
							</a-form-item>
							<a-form-item label="资产照片" class="ui-result__item" :label-col="{ span: 9 }">
								<!-- {{showFormModal.imgUrl}} -->
								<a-image :width="100" v-for="(item,index) in showFormModal.imgList" :key="index"  :src="item"></a-image>
							</a-form-item>
							<a-form-item label="使用部门" class="ui-result__item" :label-col="{ span: 9 }">
								{{showFormModal.departmentName}}
							</a-form-item>
							<a-form-item label="使用人" class="ui-result__item" :label-col="{ span: 9 }">
								{{showFormModal.user}}
							</a-form-item>
						</div>
					</a-col>
				</a-row>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
	import { postApplyScrap, getAssetsDetailSearch } from '@/service/modules/property.js';
	import filesUpload from '@/components/upload/filesUpload.vue';
	import modalHeader from '@/components/modalHeader/index.vue';
	// import assetsSelect from '@/components/assetsSelect/index.vue';
	export default {
		components: { filesUpload, modalHeader },
		data() {
			return {
				visible: false,
				// assetsIds: [],
				formModal: {
					fileList: [],
				},
				showFormModal: {},
				fileList: []
			}
		},
		methods: {
			async getData(item) {
				this.loading = true;
				try {
					let ret = await getAssetsDetailSearch({
						nameOrCode: item
					});
					this.loading = false;
					if (ret.code === 200) {
						this.showFormModal = ret.data;
						this.showFormModal.imgList = []
						let tempStr = ret.data.imgUrl
						let tempArr = tempStr? tempStr.split(','): []
						this.showFormModal.imgList.push(...tempArr)
						this.formModal.assetsId = ret.data.id
						// this.pagination.total = ret.data.totalCount;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onSubmit() {
				this.$refs.formRef.validate().then(async () => {
					let postData = this.$deepClone(this.formModal)
					// let tempIds = postData.assetsId
					// postData.assetsId = tempIds[0]
					postData.materialUrl = this.formModal.fileList.toLocaleString()
					postData = JSON.parse(JSON.stringify(postData));
					this.loading = true;
					try {
						let ret = await postApplyScrap(postData);
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('申请成功');
							this.$emit('refresh');
							this.close();
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			// onAssetDetail(e) { // 输入框改变 发送获取详情请求
			// 	let tempItem
			// 	setTimeout(()=>{
			// 		tempItem = e.target._value
			// 		this.getData(tempItem)
			// 	},0)
			// },
			open(e) {
				if (e && e.id) {
					this.getData(e.code)
					// this.showFormModal = {
					// 	id: e.id,
					// 	name: e.name,
					// 	code: e.code,
					// 	amount: e.amount,
					// 	assetsParameterId: e.assetsParameterId,
					// 	gainType: e.gainType,
					// 	organization: e.organization,
					// 	conditionType: e.conditionType,
					// 	useType: e.useType,
					// 	gainTime: e.gainTime,
					// 	unitId: e.unitId,
					// 	departmentId: e.departmentId,
					// 	user: e.user,
					// 	place: e.place,
					// 	incomeTime: e.incomeTime,
					// 	// imgUrl: e.imgUrl,
					// 	brand: e.brand,
					// 	model: e.model,
					// 	classifyName: e.classifyName,
					// 	departmentName: e.departmentName,
					// 	description: e.description,
					// 	nature: e.nature
					// }
					// this.showFormModal.imgList = []
					// let tempStr = e.imgUrl
					// let tempArr = tempStr? tempStr.split(','): []
					// this.showFormModal.imgList.push(...tempArr)
					// this.formModal.nameOrCode = e.name
					// this.onAssetsChange()
				} else {
					this.showFormModal = {}
				}
				this.visible = true;
			},
			close() {
				this.$refs.formRef.clearValidate();
				this.assetsIds = [];
				this.formModal = {
					fileList: []
				},
				this.showFormModal = {
					imgList: []
				},
				this.visible = false;
			},
			// onAssetsChange(assetsData) {
			// 	this.getData(assetsData[0].code);
			// }
		}
	}
</script>

<style scoped>
	.ui-box {
		width: 300px;
		padding: 10px;
		margin: 0 0 0 auto;
		background: #F5F7FA;
		border-radius: 10px;
	}
	.ui-result__item {
		margin-bottom: 0;
	}
</style>