import { send } from "../index";
export function getSearchListAssets(data, opts = {}) {
	return send({
		url: "/admin/assets/searchList.do",
		method: "POST",
		data,
		...opts
	})
}
// 打印标签
export function printLabel(data, opts = {}) {
	return send({
		url: "/admin/assets/printLabel.do",
		method: "POST",
		data,
		...opts
	})
}
