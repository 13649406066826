<template>
	<div>
		<a-modal v-model:visible="visible" width="800px" @cancel="close" @ok="onSubmit">
			<template #title>
				<modalHeader :title="isEdit ? '编辑资产' : '录入资产'"></modalHeader>
			</template>
			<a-spin :spinning="loading">
				<a-form ref="formRef" :model="formModal" :label-col="{ span: 7 }">
					<a-row>
						<a-col :span="12">
							<a-form-item v-if="!isEdit" label="资产编号" class="ui-form__item"
								:rules="[{ required: true, message: '必填项不允许为空' }]">
								<a-input v-model:value.number="formModal.code" placeholder="请输入资产编号"/>
							</a-form-item>
							<a-form-item v-else label="资产编号" class="ui-form__item"
								:rules="[{ required: true, message: '必填项不允许为空' }]">
								<a-input v-model:value.number="formModal.code" placeholder="请输入资产编号" disabled/>
							</a-form-item>
							<a-form-item label="资产名称" class="ui-form__item"
								:rules="[{ required: true, message: '必填项不允许为空' }]">
								<a-input v-model:value="formModal.name" placeholder="请输入资产名称"></a-input>
							</a-form-item>
							<a-form-item label="取得日期" class="ui-form__item"
								:rules="[{ required: true, message: '必填项不允许为空' }]">
								<a-date-picker v-model:value="formModal.gainTime" showTime></a-date-picker>
							</a-form-item>
							<a-form-item label="设备用途" class="ui-form__item">
								<a-select v-model:value="formModal.useType" placeholder="请选择设备用途">
									<a-select-option v-for="(item, index) in $store.state.count.usageList"
										:key="item.id" :value="index + 1">{{ item.name }}</a-select-option>
								</a-select>
							</a-form-item>
							<a-form-item label="规格型号" class="ui-form__item">
								<a-input v-model:value="formModal.model" placeholder="请输入规格型号"></a-input>
							</a-form-item>
							<a-form-item name="unitId" label="使用单位" class="ui-form__item">
								<!-- <a-select @select="getAllDepartmentList" v-model:value="formModal.unitId" placeholder="请选择所属单位" :disabled="isEdit">
									<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
								</a-select> -->
								<!-- <a-select v-model:value="formModal.unitId" placeholder="请选择所属单位" disabled>
									<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
								</a-select> -->
								<a-select v-model:value="formModal.unitId" placeholder="请选择所属单位" disabled>
									<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
								</a-select>
							</a-form-item>
							<a-form-item label="使用部门" class="ui-form__item"
								:rules="[{ required: true, message: '必填项不允许为空' }]">
								<a-select v-model:value="formModal.departmentId" placeholder="请选择使用部门"
									:disabled="isEdit" @change="onDepChange">
									<a-select-option v-for="(item, index) in depList" :key="index" :value="item.id">
										{{ item.name }}
									</a-select-option>
								</a-select>
							</a-form-item>
							<a-form-item label="使用人" class="ui-form__item"
								:rules="[{ required: true, message: '必填项不允许为空' }]">
								<!-- <a-input v-model:value="formModal.user" placeholder="请输入使用人"></a-input> -->
								<a-select v-model:value="formModal.userId" placeholder="请选择使用人" :disabled="isEdit">
									<a-select-option v-for="(item, index) in depUserList" :key="item.id"
										:value="item.id">{{ item.nickname }}</a-select-option>
								</a-select>
							</a-form-item>
							<a-form-item label="保存地点" class="ui-form__item">
								<a-input v-model:value="formModal.place" placeholder="请输入保存地点"></a-input>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="资产分类" class="ui-form__item"
								:rules="[{ required: true, message: '必填项不允许为空' }]">
								<!-- <a-select v-model:value="formModal.assetsParameterId" placeholder="请选择资产分类">
									<a-select-option v-for="(item, index) in $store.state.count.assetClassify"
										:key="item.id" :value="item.id">{{ item.name }}</a-select-option>
								</a-select> -->
								<a-cascader v-model:value="formModal.assetsParameterId" :options="$store.state.count.assetClassify" placeholder="请选择资产分类" />
							</a-form-item>
							<a-form-item label="取得方式" class="ui-form__item">
								<a-select v-model:value="formModal.gainType" placeholder="请选择取得方式">
									<a-select-option v-for="(item, index) in $store.state.count.methodList"
										:key="item.id" :value="item.code">{{ item.name }}</a-select-option>
								</a-select>
							</a-form-item>
							<a-form-item label="编制状况" class="ui-form__item">
								<a-input placeholder="在编" disabled></a-input>
							</a-form-item>
							<a-form-item label="品牌" class="ui-form__item">
								<a-input v-model:value="formModal.brand" placeholder="请输入品牌"></a-input>
							</a-form-item>
							<a-form-item label="价值" class="ui-form__item">
								<a-input-number v-model:value="formModal.amount" placeholder="请输入" :min="0"
									:precision="2"></a-input-number> 元
							</a-form-item>
							<a-form-item label="使用状况" class="ui-form__item"
								:rules="[{ required: true, message: '必填项不允许为空' }]">
								<!-- <a-input v-model:value="formModal.conditionType" placeholder="请输入使用状况"></a-input> -->
								<a-select v-model:value="formModal.conditionType" placeholder="请选择使用状况">
									<a-select-option v-for="(item, index) in $store.state.count.useStatusList"
										:key="item.id" :value="item.code">{{ item.name }}</a-select-option>
								</a-select>
							</a-form-item>
							<a-form-item label="资产归属" class="ui-form__item">
								<a-select v-model:value="formModal.nature" placeholder="请选择资产归属">
									<a-select-option :value="1">单位</a-select-option>
									<a-select-option :value="2">个人</a-select-option>
									<a-select-option :value="3">租借</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :span="24" style="padding-left: 12px;">
							<a-form-item label="备注" class="ui-form__item" :label-col="{ span: 3 }">
								<a-input v-model:value="formModal.description" placeholder="请输入备注信息"></a-input>
							</a-form-item>
						</a-col>
						<a-col :span="24" style="padding-left: 12px;">
							<a-form-item label="设备照片" class="ui-form__item" :label-col="{ span: 3 }">
								<imageUpload v-model:value="list" :limit="5" :maxSize="2"></imageUpload>
							</a-form-item>
						</a-col>
					</a-row>
				</a-form>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import {
		getAllDepartmentList,
		getAllUserList
	} from '@/service/modules/global.js';
	import {
		updateAssets,
		saveAssets,
		getAssetsClassify,
		getAssetsClassifyNode
	} from '@/service/modules/property.js';
	import {
		Icon
	} from '@/components/icon/icon.js';
	import modalHeader from '@/components/modalHeader/index.vue';
	import imageUpload from '@/components/upload/imageUpload.vue';
	import {
		mapState
	} from "vuex";
	export default {
		components: {
			Icon,
			imageUpload,
			modalHeader
		},
		data() {
			return {
				loading: false,
				visible: false,
				isEdit: false,
				formModal: {
					unitId: null
				},
				depList: [], // 选中单位是的部门列表
				list: [],
				tempDate: null,
				classifyList: [],
				depUserList: [],
			}
		},
		created() {
			// this.formModal.unitId = this.userInfo.unitId
			this.getAllDepartmentList()
			// this.getAssetsClassifyFun()
		},
		computed: {
			...mapState("system", ["userInfo"]),
		},
		methods: {
			async getAllDepartmentList() {
				// this.formModal.departmentId = null
				let ret = await getAllDepartmentList({
					unitId: this.$store.state.system.userInfo.unitId
				});
				if (ret.code === 200) {
					this.depList = ret.data
				}
			},
			async getAssetsClassifyFun() { // 获得所有资产分类
				let ret = await getAssetsClassify({});
				if (ret.code === 200) {
					this.classifyList = ret.data.map(arr => this.mapTree(arr))
				}
			},
			async getAssetsClassifyNode(id) { // 资产分类回显
				let ret = await getAssetsClassifyNode({
					id
				});
				if (ret.code === 200) {
					this.formModal.assetsParameterId = ret.data.reverse();
				}
			},
			onSubmit() {
				this.$refs.formRef.validate().then(async () => {
					let postData = this.$deepClone(this.formModal)
					if (!postData.organization) postData.organization = 1
					postData.imgUrl = this.list.join(',')
					let temp = this.transDateTime(postData.gainTime)
					let tempTime = new Date(temp)
					postData.gainTime = tempTime.valueOf()
					postData.gainTime = this.moment(postData.gainTime).unix();
					let tempLen =  postData.assetsParameterId.length
					postData.assetsParameterId = postData.assetsParameterId[tempLen - 1]
					postData = JSON.parse(JSON.stringify(postData));
					this.loading = true;
					try {
						let ret;
						if (this.isEdit) {
							ret = await updateAssets(postData);
						} else {
							ret = await saveAssets(postData);
						}
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success(this.isEdit ? '编辑成功' : '新增成功');
							this.$emit('refresh');
							this.close();
						}
					} catch (e) {
						this.loading = false;
					}
				})
			},
			mapTree(arr) {
				const haveChild = Array.isArray(arr.children) && arr.children.length > 0;
				return {
					value: arr.id,
					label: arr.name,
					children: haveChild ? arr.children.map(i => this.mapTree(i)) : []
				}
			},
			open(e) {
				this.formModal.unitId = this.userInfo.unitId
				if (e && e.id) {
					this.formModal = {
						id: e.id,
						name: e.name,
						code: e.code,
						amount: e.amount,
						assetsParameterId: e.assetsParameterId,
						gainType: e.gainType === 0 ? null : e.gainType + '',
						organization: e.organization,
						conditionType: e.conditionType + '',
						useType: e.useType === 0 ? null : e.useType,
						// gainTime: e.gainTime,
						unitId: e.unitId,
						departmentId: e.departmentId,
						user: e.user,
						place: e.place,
						incomeTime: e.incomeTime,
						// imgUrl: e.imgUrl,
						brand: e.brand,
						model: e.model,
						classifyName: e.classifyName,
						departmentName: e.departmentName,
						description: e.description,
						nature: e.nature!==0 ? e.nature : null,
						userId: e.userId
					}
					let tempStr = e.imgUrl
					let tempArr = tempStr ? tempStr.split(',') : []
					this.list.push(...tempArr)
					this.formModal.gainTime = this.moment.unix(e.gainTime)
					this.isEdit = true;
					// this.getAssetsClassifyFun()
					this.getAssetsClassifyNode(e.assetsParameterId)
					if (e.departmentId) {
						this.onDepChange(e.departmentId, true);
					}
				} else {
					// this.getAssetsClassifyFun()
					this.formModal = {
						unitId: null
					},
					this.formModal.unitId = this.userInfo.unitId
					this.isEdit = false;
				}
				this.visible = true;
			},
			close() {
				this.$refs.formRef.clearValidate();
				this.formModal = {}
				this.list = []
				this.classifyList = []
				this.depUserList = [];
				this.visible = false;
			},
			async onDepChange(e, isDetail) {
				if (!isDetail) {
					this.formModal.userId = undefined;
					this.depUserList = [];
				}
				try {
					// this.loading = true;
					let ret = await getAllUserList({
						departmentId: e
					});
					// this.loading = false;
					if (ret.code === 200) {
						this.depUserList = ret.data;
					}
				} catch(e) {
					// this.loading = false;
				}
			},
			// onSelDate(dateString){
			// 	console.log('newval',dateString)
			// 	let temp = this.transDateTime(dateString)
			// 	let tempTime = new Date(temp)
			// 	console.log('newval2',tempTime)
			// 	this.formModal.gainTime = tempTime.valueOf()
			// }
		},
		watch: {
			// tempDate(newVal,oldVal) {
			// 	console.log('newval',newVal)
			// 	let temp = this.transDateTime(newVal)
			// 	let tempTime = new Date(temp)
			// 	this.formModal.gainTime = tempTime.valueOf()
			// }
		}
	}
</script>

<style scoped>

</style>
