<template>
	<div>
		<a-modal v-model:visible="visible" width="750px" @cancel="close">
			<template #title>
				<modalHeader title="资产详情"></modalHeader>
			</template>
			<template #footer>
				<a-button v-permission="['pc_assets_myself_print']" type="primary" @click="onPrint">
					<template #icon><Icon icon="PrinterOutlined"/></template>
					打印标签</a-button>
				<a-button v-permission="['pc_assets_myself_edit']" @click="onEdit" type="primary" style="background: #49D4F2;border-color: #49D4F2;">
					<template #icon><Icon icon="EditOutlined"/></template>
					修改资产</a-button>
				<a-button v-permission="['pc_assets_myself_scrap']" danger @click="onDiscard">
					<template #icon><Icon icon="CloseOutlined"/></template>
					资产作废</a-button>
			</template>
			
			<a-form ref="formRef" :model="formModel" :label-col="{ span: 8 }">
				<a-row>
					<a-col :span="12">
						<a-form-item label="资产编号" class="ui-form__item">{{formModel.code}}</a-form-item>
						<a-form-item label="资产名称" class="ui-form__item">{{formModel.name}}</a-form-item>
						<a-form-item label="取得日期" class="ui-form__item">{{transDateTime(formModel.gainTime)}}</a-form-item>
						<a-form-item label="设备用途" class="ui-form__item">
							<!-- {{formModel.useType}} -->
							<div v-if="formModel.useType === 1">办公</div>
							<div v-if="formModel.useType === 2">教学</div>
							<div v-if="formModel.useType === 3">培训</div>
							<div v-if="formModel.useType === 4">执法</div>
							<div v-if="formModel.useType === 5">窗口</div>
							<div v-if="formModel.useType === 6">专用</div>
							<div v-if="formModel.useType === 7">其他</div>
							<div v-if="formModel.useType === 8">医疗</div>
							<div v-if="formModel.useType === 9">科研</div>
						</a-form-item>
						<a-form-item label="规格型号" class="ui-form__item">{{formModel.model}}</a-form-item>
						<a-form-item label="使用部门" class="ui-form__item">{{formModel.departmentName}}</a-form-item>
						<a-form-item label="保存地点" class="ui-form__item">{{formModel.place}}</a-form-item>
						<a-form-item label="资产归属" class="ui-form__item">
							<!-- {{formModel.nature}} -->
							{{formModel.nature == 1?'单位':(formModel.nature == 2?'个人':'租借')}}
						</a-form-item>
					</a-col>
					<a-col :span="12">
						<a-form-item label="资产分类名称" class="ui-form__item">{{formModel.classifyName}}</a-form-item>
						<a-form-item label="取得方式" class="ui-form__item">
						<!-- {{formModel.gainType}} -->
							<div v-if="formModel.gainType === 1">新购</div>
							<div v-if="formModel.gainType === 2">调拨</div>
							<div v-if="formModel.gainType === 3">接受捐赠</div>
							<div v-if="formModel.gainType === 4">自建</div>
							<div v-if="formModel.gainType === 5">置换</div>
							<div v-if="formModel.gainType === 6">盘盈</div>
							<div v-if="formModel.gainType === 7">自行研制</div>
							<div v-if="formModel.gainType === 8">其他</div>
						</a-form-item>
						<a-form-item label="编制情况" class="ui-form__item">
							<div v-if="formModel.organization === 1">在编</div>
						</a-form-item>
						<a-form-item label="品牌" class="ui-form__item">{{formModel.brand}}</a-form-item>
						<a-form-item label="价值(元)" class="ui-form__item">{{formModel.amount}}</a-form-item>
						<a-form-item label="使用人" class="ui-form__item">{{formModel.user}}</a-form-item>
						<a-form-item label="使用状况" class="ui-form__item">
							<!-- {{formModel.conditionType}} -->
							<div v-if="formModel.conditionType === 1">在用</div>
							<div v-if="formModel.conditionType === 2">出租</div>
							<div v-if="formModel.conditionType === 3">出借</div>
							<div v-if="formModel.conditionType === 4">闲置</div>
							<div v-if="formModel.conditionType === 5">待处置</div>
						</a-form-item>
					</a-col>
					<a-col :span="24">
						<a-form-item label="备注" class="ui-form__item" :label-col="{ span: 4 }">
							{{formModel.description}}
						</a-form-item>
					</a-col>
					<a-col :span="24">
						<a-form-item label="设备照片" class="ui-form__item" :label-col="{ span: 4 }">
							<a-image :width="130" v-for="(item,index) in list" :key="index"  :src="item"></a-image>
						</a-form-item>
					</a-col>
				</a-row>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
	import modalHeader from '@/components/modalHeader/index.vue';
	import { Icon } from '@/components/icon/icon.js';
	export default {
		components: { Icon, modalHeader },
		data() {
			return {
				formModel: {},
				list: [],
				visible: false,
			}
		},
		methods: {
			open(e) {
				if(e && e.id) {
					this.formModel = {
						id: e.id,
						name: e.name,
						code: e.code,
						amount: e.amount,
						assetsParameterId: e.assetsParameterId,
						gainType: e.gainType,
						organization: e.organization,
						conditionType: e.conditionType,
						useType: e.useType,
						gainTime: e.gainTime,
						unitId: e.unitId,
						departmentId: e.departmentId,
						user: e.user,
						place: e.place,
						incomeTime: e.incomeTime,
						// imgUrl: e.imgUrl, //
						brand: e.brand,
						model: e.model,
						classifyName: e.classifyName,
						departmentName: e.departmentName,
						nature: e.nature,
						description: e.description
					}
					let tempStr = e.imgUrl
					let tempArr = tempStr? tempStr.split(','): []
					this.list.push(...tempArr)
				}
				this.visible = true;
			},
			close() {
				this.formModel = {}
				this.list = []
				this.visible = false;
			},
			onEdit() {
				this.$emit("onEdit",this.formModel)
				this.close()
			},
			onDiscard() {
				this.$emit("onDiscard",this.formModel)
				this.close()
			},
			onPrint() {
				this.close()
				this.$emit("onPrint",this.formModel.id)
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-bottom: 10px !important;
	}
</style>